import { useTranslation } from 'react-i18next'

import { ChartPageParams } from 'api/models'
import { PsChartFeatures, PsChartStore } from 'components/ps-chart/PsChartStore'
import { AnnotationList } from 'components/annotations/details-view/list/AnnotationList'
import { UnsavedAnnotationModal } from 'components/annotations/details-view/list/UnsavedAnnotationModal'
import { AnnotationsStore } from 'components/ps-chart/stores/AnnotationsStore'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'
import { Button, ButtonVariantEnum } from 'components/Button'
import { useCallback, useEffect, useState } from 'react'
import { useBlocker } from 'react-router-dom'
import { useToaster } from 'hooks/useToaster'
import { ActionTooltip } from 'components/ActionTooltip'
import { observer } from 'mobx-react-lite'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { reaction } from 'mobx'
import { showGotItToaster } from 'components/ToastGotIt'
import { ELEMENTS_IDS } from 'components/ps-chart/elementsIds'
import { FlowList } from 'components/ps-chart/details-view/flags/FlowList'
import { CreateAnnotationForm } from './create/CreateAnnotationForm'
import { FlowInfo } from '../FlowInfo'

interface Props {
  annotationsStore: AnnotationsStore
  videoPlayerState: VideoPlayerState
  chartPageParams: ChartPageParams
  chartFeatures: PsChartFeatures
  psChartStore: PsChartStore
}

export const AnnotationsDetails = observer(function AnnotationsDetails({
  annotationsStore,
  videoPlayerState,
  chartPageParams,
  chartFeatures,
  psChartStore,
}: Props) {
  const { t } = useTranslation()
  const toaster = useToaster()

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      newAnnotationId !== null && currentLocation.pathname !== nextLocation.pathname,
  )

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [newAnnotationId, setNewAnnotationId] = useState<number | null>(null)

  const createNewAnnotation = useCallback(() => {
    if (videoPlayerState.hasFullData) {
      const newAnnotation = annotationsStore.createLocally()
      setNewAnnotationId(newAnnotation.id)
    } else {
      toaster.error(t('annotations.validation.specifyActionTitle'), 'annotation.error.create')
    }
  }, [annotationsStore, t, toaster, videoPlayerState.hasFullData])

  const saveNewAnnotation = useCallback(() => {
    if (newAnnotationId === null) {
      return
    }
    const newAnnotation = annotationsStore.getById(newAnnotationId)!
    if (!newAnnotation.action.title) {
      toaster.error(t('annotations.validation.specifyActionTitle'))
      return
    }
    if (!newAnnotation.reaction.title) {
      toaster.error(t('annotations.validation.specifyReactionTitle'))
      return
    }

    const tempAnnotationId = newAnnotationId
    setNewAnnotationId(null)

    annotationsStore.create(newAnnotationId).catch((reason) => {
      toaster.error(reason, 'annotation.error.create')
      setNewAnnotationId(tempAnnotationId)
    })
  }, [annotationsStore, newAnnotationId, t, toaster])

  useEffect(
    () =>
      reaction(
        () => annotationsStore.annotationsDataStore.hasRemoteMappedAnnotations,
        (hasRemoteMappedAnnotations) => {
          if (hasRemoteMappedAnnotations) {
            const messageId = 'annotations.create.success'
            const alreadyGotIt = psLocalStorage.hasGotIt(messageId)
            if (alreadyGotIt) {
              toaster.info(messageId)
            } else {
              showGotItToaster(t(messageId), messageId)
            }
          }
        },
        { name: 'reaction @ AnnotationDetails & hasRemoteMappedAnnotations -> toaster' },
      ),
    [annotationsStore, toaster, t],
  )

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsConfirmModalOpen(true)
    } else {
      setIsConfirmModalOpen(false)
    }
  }, [blocker])

  const cancelNewAnnotation = useCallback(() => {
    if (newAnnotationId === null) {
      return
    }
    annotationsStore
      .delete(newAnnotationId)
      .catch((reason) => toaster.error(reason, 'annotation.error.delete'))
    setNewAnnotationId(null)
  }, [annotationsStore, newAnnotationId, toaster])

  const proceedModal = useCallback(() => {
    if (blocker.state === 'blocked') {
      cancelNewAnnotation()
      blocker.proceed()
    }
  }, [cancelNewAnnotation, blocker])

  const closeModal = useCallback(() => {
    if (blocker.state === 'blocked') {
      blocker.reset()
    }
  }, [blocker])

  return (
    <div
      id={ELEMENTS_IDS.CHART_DETAILS_VIEW}
      className="overflow-y-auto absolute top-0 right-0 bottom-0 left-0 p-[24px]"
    >
      {chartFeatures.annotations.flowAccess && <FlowInfo chartPageParams={chartPageParams} />}

      {annotationsStore.notPlacedOnTimelineAnnotations.length > 0 ? (
        <div className="text-small text-gray-normal my-[16px]">
          {t('annotations.create.unmapped')}
        </div>
      ) : null}
      <AnnotationList
        mapped={false}
        annotationsStore={annotationsStore}
        videoPlayerState={videoPlayerState}
      />

      <div className="text-small text-gray-normal mb-[16px] mt-[16px]">
        {t('annotations.create.annotations')}
      </div>
      <div className="mb-[10px]">
        {newAnnotationId === null && (
          <ActionTooltip
            disabled={videoPlayerState.hasFullData}
            place="bottom"
            tooltipId="noVideoWarning"
          >
            <Button
              onClick={createNewAnnotation}
              variant={ButtonVariantEnum.Outlined}
              className="w-full"
              isSmall
              disabled={!videoPlayerState.hasFullData}
            >
              {t('annotations.create.addAnnotation')}
            </Button>
          </ActionTooltip>
        )}
      </div>

      {newAnnotationId !== null && (
        <>
          <CreateAnnotationForm
            annotationId={newAnnotationId}
            annotationsStore={annotationsStore}
            videoPlayerState={videoPlayerState}
            onSave={saveNewAnnotation}
            onCancel={cancelNewAnnotation}
          />
          <UnsavedAnnotationModal
            isOpen={isConfirmModalOpen}
            onProceed={proceedModal}
            onClose={closeModal}
          />
        </>
      )}

      <AnnotationList
        mapped={true}
        annotationsStore={annotationsStore}
        videoPlayerState={videoPlayerState}
      />

      <FlowList flagsStore={psChartStore.flagsStore} psChartStore={psChartStore} />
    </div>
  )
})
