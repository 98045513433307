import React, { useContext, useMemo } from 'react'
import { useFeatureFlag, FEATURE_FLAGS } from 'utils/feature-flags'

import { Layout } from 'components/Layout'
import { PsChart } from 'components/ps-chart/PsChart'
import { PsChartFeatures, psChartStoreContext } from 'components/ps-chart/PsChartStore'
import { FlagDisplayMode } from 'components/ps-chart/models/Flag'
import { useNavigationRules } from 'hooks/useNavigationRules'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { Navbar } from 'components/Navbar'
import { TracePageBreadcrumbs } from 'components/breadcrumbs/TracePageBreadcrumbs'

export const PATH_CHART_FOLDER = 'traceviewer'
export const PATH_CHART = `/projects/:projectUrlName/flows/:flowProjectLocalId/traces/:traceProjectLocalId/${PATH_CHART_FOLDER}`
export const PATH_CHART_DIRECT = '/projects/:projectUrlName/traces/:traceProjectLocalId'

const defaultChartFeatures = ({
  annotationsEnabled,
  directionToolEnabled,
}: {
  annotationsEnabled?: boolean
  directionToolEnabled?: boolean
}): PsChartFeatures => {
  return {
    flags: {
      enabled: true,
      clickable: true,
      mode: FlagDisplayMode.ALL,
    },
    measurementTool: true,
    annotations: {
      enabled: !!annotationsEnabled,
      draggable: false,
      clickable: true,
      flowAccess: true,
    },
    directionTool: !!directionToolEnabled,
  }
}

export const PsChartPage = () => {
  const psChartStore = useContext(psChartStoreContext)!

  const annotationsEnabled = useFeatureFlag(FEATURE_FLAGS.ANNOTATIONS)
  const directionToolEnabled = useFeatureFlag(FEATURE_FLAGS.DIRECTION_TOOL)

  useNavigationRules()

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={<TracePageBreadcrumbs />}
        centerContent={<PageTitle titleKey="flameChart" />}
      />
    ),
    [],
  )
  const footer = useMemo(() => <Navbar />, [])

  if (annotationsEnabled === undefined) {
    return null
  }
  psChartStore.setChartFeatures(defaultChartFeatures({ annotationsEnabled, directionToolEnabled }))

  return (
    <Layout
      headerComponent={header}
      footerComponent={footer}
      pageConfig={{ withoutStyledContent: true }}
    >
      <PsChart psChartStore={psChartStore} />
    </Layout>
  )
}
