import { makeAutoObservable, reaction } from 'mobx'

/**
 * Store to manage hidden flow IDs
 * This store persists hidden flow IDs in localStorage, scoped to the specific project and flow
 */
export class HiddenFlowsStore {
  private hiddenFlowIds: Set<number> = new Set()

  constructor(private projectId: string, private flowId: string) {
    makeAutoObservable(this)
    this.loadFromStorage()
  }

  /**
   * Register a callback that will be called when hidden flows change
   * Returns a disposer function
   */
  onHiddenFlowsChange(callback: () => void): () => void {
    // Use MobX reaction to track changes to hiddenFlowIds
    return reaction(
      () => this.getHiddenFlowIds(),
      () => callback(),
      { fireImmediately: false },
    )
  }

  /**
   * Check if a flow ID is hidden
   */
  isHidden(flowId: number): boolean {
    return this.hiddenFlowIds.has(flowId)
  }

  /**
   * Hide a flow by ID
   */
  hideFlow(flowId: number): void {
    // Create a new set to ensure observable change detection
    const newSet = new Set(this.hiddenFlowIds)
    newSet.add(flowId)
    this.hiddenFlowIds = newSet
    this.saveToStorage()
  }

  /**
   * Show a flow by ID
   */
  showFlow(flowId: number): void {
    // Create a new set to ensure observable change detection
    const newSet = new Set(this.hiddenFlowIds)
    newSet.delete(flowId)
    this.hiddenFlowIds = newSet
    this.saveToStorage()
  }

  /**
   * Toggle a flow's visibility by ID
   */
  toggleFlow(flowId: number): void {
    if (this.isHidden(flowId)) {
      this.showFlow(flowId)
    } else {
      this.hideFlow(flowId)
    }
  }

  /**
   * Get all hidden flow IDs
   */
  getHiddenFlowIds(): number[] {
    return Array.from(this.hiddenFlowIds)
  }

  /**
   * Get the storage key for this project and flow
   */
  private get storageKey(): string {
    return `hidden-flows-${this.projectId}-${this.flowId}`
  }

  /**
   * Load hidden flow IDs from localStorage
   */
  private loadFromStorage(): void {
    try {
      const storedValue = localStorage.getItem(this.storageKey)
      if (storedValue) {
        const hiddenIds = JSON.parse(storedValue) as number[]
        this.hiddenFlowIds = new Set(hiddenIds)
      }
    } catch (error) {
      console.error('Failed to load hidden flow IDs from localStorage', error)
    }
  }

  /**
   * Save hidden flow IDs to localStorage
   */
  private saveToStorage(): void {
    try {
      const hiddenIdsArray = Array.from(this.hiddenFlowIds)
      localStorage.setItem(this.storageKey, JSON.stringify(hiddenIdsArray))
    } catch (error) {
      console.error('Failed to save hidden flow IDs to localStorage', error)
    }
  }
}
