import { Api } from 'api/Api'
import { TraceDataStore } from 'components/ps-chart/stores/TraceDataStore'
import { VideoDataStore } from 'components/ps-chart/stores/VideoDataStore'
import { AnnotationsDataStore } from 'components/ps-chart/stores/AnnotationsDataStore'
import {
  GetConnectionsDto,
  ChartPageParams,
  TraceSettingsDto,
  MetricsMethodTag,
  NamedLinkDto,
} from 'api/models'
import { makeAutoObservable, runInAction } from 'mobx'
import React from 'react'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { HorizontalStateStore } from 'components/ps-chart/stores/HorizontalStateStore'
import { FlagsDataStore } from 'components/ps-chart/stores/FlagsDataStore'
import { connectionMetrics } from 'utils/ConnectionsMetrics'

export const chartDataStoreContext = React.createContext<ChartDataStore | null>(null)

export class ChartDataStore {
  private readonly api: Api
  private readonly chartPageParams: ChartPageParams
  readonly settings: PsChartSettings

  isLoading = false

  readonly traceDataStore: TraceDataStore
  readonly hStateStore: HorizontalStateStore
  readonly videoDataStore: VideoDataStore
  readonly annotationsDataStore: AnnotationsDataStore
  readonly flagsDataStore: FlagsDataStore

  namedLinks: NamedLinkDto[] | null = null
  choreographerPaths: TraceSettingsDto | null = null
  beConnectionsData: GetConnectionsDto | null = null

  constructor(api: Api, chartPageParams: ChartPageParams, settings: PsChartSettings) {
    makeAutoObservable<ChartDataStore, 'api'>(this, {
      api: false,
      settings: false,
    })
    this.api = api
    this.settings = settings
    this.chartPageParams = chartPageParams
    this.traceDataStore = new TraceDataStore(api, chartPageParams, this.settings)
    this.hStateStore = new HorizontalStateStore(this.traceDataStore, this.settings)
    this.videoDataStore = new VideoDataStore(api, chartPageParams)
    this.annotationsDataStore = new AnnotationsDataStore(api, chartPageParams)
    this.flagsDataStore = new FlagsDataStore(api, chartPageParams, this.traceDataStore.sliceById)
  }

  private setIsLoading(value: boolean) {
    this.isLoading = value
  }

  load(): Promise<void> {
    this.setIsLoading(true)

    // Load trace data first to ensure slices are available
    return this.traceDataStore
      .load()
      .then(() => {
        // Now that slices are loaded, we can proceed with other data
        return Promise.all([
          this.annotationsDataStore.load(true),
          this.videoDataStore.load(),
          this.flagsDataStore.load(),
          this.fetchNamedLinks(),
          this.fetchChoreographerPaths(),
        ])
      })
      .then(() => undefined)
      .finally(() => {
        this.fetchTraceConnections()
        this.setIsLoading(false)
      })
  }

  private fetchNamedLinks(): Promise<void> {
    const fetchStart = performance.now()
    return this.api.getNamedLinks(this.chartPageParams).then((namedLinks) => {
      connectionMetrics.addConnectionsHandlingTime(
        performance.now() - fetchStart,
        MetricsMethodTag.frontendConnections,
        'api::getNamedLinks',
      )
      const fetchNamedLinks = () => (this.namedLinks = namedLinks)
      runInAction(fetchNamedLinks)
    })
  }

  fetchTraceConnections(): Promise<void> {
    const fetchStart = performance.now()
    return this.api
      .getTraceConnections(
        this.chartPageParams.projectUrlName,
        this.chartPageParams.traceProjectLocalId,
      )
      .then((connections) => {
        console.debug(
          '#CM ChartDataStore->api.getTraceConnections->then [performance.now]',
          window.performance.now(),
        )
        connectionMetrics.addConnectionsHandlingTime(
          performance.now() - fetchStart,
          MetricsMethodTag.backendConnections,
          'api::getTraceConnections',
        )
        const fetchTraceConnections = () => (this.beConnectionsData = connections)
        runInAction(fetchTraceConnections)
      })
      .catch((error) => {
        //TODO: change the handler once the feature is out of beta
        console.error('Failed to fetch trace connections', error)
      })
  }

  fetchChoreographerPaths(): Promise<void> {
    return this.api.getChoreographerPaths(this.chartPageParams).then((paths) => {
      const fetchChoreographerPaths = () => (this.choreographerPaths = paths)
      runInAction(fetchChoreographerPaths)
    })
  }
}
