import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { autorun } from 'mobx'
import {
  LocalTimelineListener,
  LocalTimelineRenderer,
  OnAnnotationClick,
  OnAnnotationDragStart,
  OnAnnotationHover,
  OnAnnotationTimeChange,
  OnFlagAdd,
  OnFlagClick,
  OnFlagPosChange,
  OnShowHover,
  OnVideoPointerClick,
} from 'components/ps-chart/local-timeline/LocalTimelineRenderer'
import { LocalTimelineSettings } from 'components/ps-chart/local-timeline/LocalTimelineSettings'
import { ChartPageParams } from 'api/models'
import { useToaster } from 'hooks/useToaster'
import { AnnotationIdAndType } from 'components/ps-chart/stores/AnnotationsStore'
import { RenderEngine } from 'components/ps-chart/flame-chart/RenderEngine'
import {
  FlagSource,
  INSTRUMENTATION_FLAG_SOURCE_SLICE_SEARCH_TERM,
} from 'components/ps-chart/models/Flag'
import { SearchStore } from 'components/ps-chart/actions-panel/SearchStore'

export interface LocalTimelineProps {
  psChartStore: PsChartStore
  tracePageParams: ChartPageParams
}

export const LocalTimeline = observer(function LocalTimeline({ psChartStore }: LocalTimelineProps) {
  const containerId = 'localTimeline'
  const localTimelineRef = useRef<HTMLDivElement>(null)
  const [renderer, setRenderer] = useState<LocalTimelineRenderer>()
  const toaster = useToaster()
  const { t } = useTranslation()

  const videoPointerClick = useCallback<OnVideoPointerClick>(
    (time, isDragStart, isDragEnd) => {
      psChartStore.videoPlayerStore.setVideoPointerTimeNanos(time)
      if (psChartStore.videoPlayerStore.checkTimeInBounds(time)) {
        if (isDragStart) {
          psChartStore.videoPlayerStore.rememberVideoPointerDragPlayingStatus()
        }
        if (isDragEnd) {
          psChartStore.videoPlayerStore.clearVideoPointerDragPlayingStatus()
        }
      }
    },
    [psChartStore],
  )

  const addFlag = useCallback<OnFlagAdd>(
    (time) => {
      psChartStore.flagsStore
        .add(time)
        .catch((reason) => toaster.error(reason, 'psChart.flag.error.add'))
    },
    [psChartStore.flagsStore, toaster],
  )

  const changeFlagPosLocally = useCallback<OnFlagPosChange>(
    (time: number, id: number, cid?: number) => {
      psChartStore.flagsStore.updateTimeLocally(time, id, cid)
    },
    [psChartStore],
  )

  const changeFlagPos = useCallback<OnFlagPosChange>(
    (time: number, id: number, cid?: number) => {
      psChartStore.flagsStore
        .updateTime(time, id, cid)
        .catch((reason) => toaster.error(reason, 'psChart.flag.error.changePos'))
    },
    [psChartStore.flagsStore, toaster],
  )

  const clickFlag = useCallback<OnFlagClick>(
    (id: number, cid: number | undefined) => {
      const clickedFlag = psChartStore.flagsStore.flagsDataStore.getByIdOrCid(id, cid)
      if (clickedFlag !== undefined && clickedFlag.source === FlagSource.INSTRUMENTATION) {
        const instrumentationSlice = SearchStore.manualSearch(
          INSTRUMENTATION_FLAG_SOURCE_SLICE_SEARCH_TERM,
          psChartStore.sliceById,
          clickedFlag.time,
        )
        if (instrumentationSlice !== undefined) {
          return psChartStore.setSelectedSlice(instrumentationSlice)
        }
      }
      return psChartStore.selectFlag(id, cid)
    },
    [psChartStore],
  )

  const showHoverFlag = useCallback<OnShowHover>(
    (time: number | null) => {
      return psChartStore.flagsStore.setShowHover(time)
    },
    [psChartStore],
  )

  const showHoverAnnotation = useCallback<OnAnnotationHover>(
    (idAndPinType: AnnotationIdAndType | null) => {
      return psChartStore.annotationsStore.setHoveredId(idAndPinType)
    },
    [psChartStore],
  )

  const clickAnnotation = useCallback<OnAnnotationClick>(
    (idAndPinType: AnnotationIdAndType | null) => {
      if (psChartStore.annotationsStore.featureState.draggable) {
        return psChartStore.annotationsStore.setSelectedId(idAndPinType)
      }

      if (psChartStore.chartFeatures.annotations.clickable) {
        const binding = psChartStore.annotationsStore.getBindingByIdAndPinType(idAndPinType!)
        if (binding?.sliceId) {
          const slice = psChartStore.traceAnalyzeStore.sliceById.get(binding.sliceId)

          if (slice != null) {
            return psChartStore.setSelectedSlice(slice)
          }
        }
      }
    },
    [psChartStore],
  )

  const changeAnnotationPosLocally = useCallback<OnAnnotationTimeChange>(
    (time: number, idAndType: AnnotationIdAndType) => {
      psChartStore.annotationsStore.updateTimeLocally(time, idAndType)
    },
    [psChartStore.annotationsStore],
  )

  const changeAnnotationPos = useCallback<OnAnnotationTimeChange>(
    (time: number, idAndType: AnnotationIdAndType) => {
      psChartStore.annotationsStore
        .updateTime(time, idAndType)
        .catch((reason) => toaster.error(reason, 'psChart.annotation.error.changePos'))
    },
    [psChartStore.annotationsStore, toaster],
  )

  const showHoverVideoPointer = useCallback<OnShowHover>(
    (time: number | null) => {
      return psChartStore.videoPlayerStore.setShowHover(time)
    },
    [psChartStore],
  )

  const handlePinDragStart = useCallback<OnAnnotationDragStart>(
    (idAndPinType) => {
      const binding = psChartStore.annotationsStore.getBindingByIdAndPinType(idAndPinType)
      if (
        binding?.sliceId &&
        localTimelineRef.current &&
        psChartStore.chartFeatures.annotations.draggable
      ) {
        const x = RenderEngine.timeToPosition(
          binding.time,
          psChartStore.hState.xStart,
          psChartStore.hState.timePerPx,
        )
        const rect = localTimelineRef.current.getBoundingClientRect()
        const tooltip = {
          title: t('tooltips.annotationBlocked'),
          point: { x: rect.left + x, y: rect.top + rect.height },
          settings: {
            animated: true,
            showAnimationTime: 400,
            hideDelay: 3000,
            hideAnimationTime: 300,
          },
        }
        psChartStore.setTooltip({ ...tooltip, visible: true })
        // Hide after delay
        psChartStore.setTooltip({ ...tooltip, visible: false })
      }
    },
    [psChartStore, t],
  )

  useEffect(() => {
    if (!psChartStore.isLoaded) {
      return
    }
    const settings = new LocalTimelineSettings()

    const listener: LocalTimelineListener = !psChartStore.isStaticPageMode
      ? {
          onFlagAdd: addFlag,
          onFlagPosChangeLocally: changeFlagPosLocally,
          onFlagPosChange: changeFlagPos,
          onFlagClick: clickFlag,
          onShowHoverFlag: showHoverFlag,
          onShowHoverVideoPointer: showHoverVideoPointer,
          onVideoPointerClick: videoPointerClick,
          onAnnotationPosChangeLocally: changeAnnotationPosLocally,
          onAnnotationPosChange: changeAnnotationPos,
          onAnnotationHover: showHoverAnnotation,
          onAnnotationClick: clickAnnotation,
          onAnnotationDragStart: handlePinDragStart,
        }
      : {
          onFlagAdd: () => {},
          onFlagPosChangeLocally: () => {},
          onFlagPosChange: () => {},
          onFlagClick: () => {},
          onShowHoverFlag: () => {},
          onShowHoverVideoPointer: () => {},
          onVideoPointerClick: () => {},
          onAnnotationPosChangeLocally: () => {},
          onAnnotationPosChange: () => {},
          onAnnotationHover: () => {},
          onAnnotationClick: () => {},
          onAnnotationDragStart: () => {},
        }

    const localTimeline = new LocalTimelineRenderer(
      containerId,
      localTimelineRef,
      psChartStore.hState.xMin,
      psChartStore.hState.xMax,
      psChartStore.hState.timePerPx,
      psChartStore.hState.xGridLines,
      psChartStore.flagsStore,
      psChartStore.videoPlayerStore,
      psChartStore.annotationsStore,
      psChartStore.chartFeatures,
      settings,
      listener,
      psChartStore.hiddenFlowsStore,
    )
    localTimeline.addEventListeners()
    setRenderer(localTimeline)
    return () => {
      localTimeline.removeEventListeners()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFlag, changeFlagPosLocally, changeFlagPos, psChartStore.isLoaded, psChartStore.hiddenFlowsStore])

  useEffect(
    () =>
      autorun(
        () => {
          if (psChartStore.isLoaded) {
            renderer?.updateFlags(psChartStore.flagsStore)
          }
        },
        { name: 'autorun @ LocalTimeline -> updateFlags' },
      ),
    [renderer, psChartStore],
  )

  useEffect(
    () =>
      autorun(
        () => {
          if (psChartStore.isLoaded) {
            renderer?.renderVideoPointer(psChartStore.videoPlayerStore)
          }
        },
        { name: 'autorun @ LocalTimeline -> renderVideoPointer' },
      ),
    [renderer, psChartStore],
  )

  useEffect(
    () =>
      autorun(
        () => {
          if (psChartStore.isLoaded) {
            renderer?.updateState(
              psChartStore.hState.width,
              psChartStore.hState.xStart,
              psChartStore.hState.xEnd,
              psChartStore.hState.timePerPx,
              psChartStore.hState.xGridLines,
              psChartStore.flagsStore,
              psChartStore.videoPlayerStore,
              psChartStore.annotationsStore,
            )
          }
        },
        { name: 'autorun @ LocalTimeline -> updateState' },
      ),
    [
      renderer,
      psChartStore,
      psChartStore.annotationsStore.isLoaded,
      psChartStore.chartFeatures,
      psChartStore.flagsStore,
    ],
  )

  return <div id={containerId} className="h-[56px]" ref={localTimelineRef} />
})
