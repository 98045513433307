import { makeAutoObservable, observable, runInAction } from 'mobx'
import { Api } from 'api/Api'

import { ChartPageParams } from 'api/models'
import {
  Flag,
  FlagSource,
  getColor,
  INSTRUMENTATION_FLAG_SOURCE_SLICE_SEARCH_TERM,
  extractFlowIdFromTitle,
} from 'components/ps-chart/models/Flag'
import { Flags } from 'components/ps-chart/stores/FlagsStore'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'

export class FlagsDataStore {
  private readonly api: Api
  private readonly chartPageParams: ChartPageParams
  private readonly _sliceById?: ReadonlySliceById

  private _flags: Flag[] = []
  private _isLoaded = false

  constructor(api: Api, chartPageParams: ChartPageParams, sliceById?: ReadonlySliceById) {
    makeAutoObservable<FlagsDataStore, 'api' | 'tracePageParams' | '_flags' | '_sliceById'>(this, {
      api: false,
      tracePageParams: false,
      _flags: observable.shallow,
      _sliceById: false,
    })
    this.api = api
    this.chartPageParams = chartPageParams
    this._sliceById = sliceById
  }

  load(): Promise<void> {
    return this.api.getFlags(this.chartPageParams).then((flags) => {
      const fetchFlags = () => {
        this.setIsLoaded(true)
        this._flags = this._flags.filter((flag) => flag.id <= 0) // filter not to delete locally added before fetch request came
        flags.forEach((flag) => {
          // Find related slices for INSTRUMENTATION flags first
          if (flag.source === FlagSource.INSTRUMENTATION && this._sliceById) {
            this.findRelatedSliceForFlag(flag)
          }
          flag.color = getColor(flag)
          this._flags.push(flag)
        })
      }
      runInAction(fetchFlags)
    })
  }

  /**
   * Find a slice with a title starting with '@FLOW' and with a time period that matches the flag's time
   */
  private findRelatedSliceForFlag(flag: Flag): void {
    if (!this._sliceById) {
      return
    }

    // Search all slices for a match
    for (const [sliceId, slice] of this._sliceById.entries()) {
      // Check if slice title starts with @FLOW
      if (slice.title.startsWith(INSTRUMENTATION_FLAG_SOURCE_SLICE_SEARCH_TERM)) {
        // Check if flag time falls within the slice time period
        if (slice.start <= flag.time && slice.end >= flag.time) {
          // Found a match - store the slice info in the flag
          flag.sliceId = sliceId
          flag.sliceTitle = slice.title

          // Extract and store the flowId
          flag.flowId = extractFlowIdFromTitle(slice.title)

          break // Stop after finding the first match
        }
      }
    }
  }

  substitute(incomingFlags: Flag[]) {
    this._flags = incomingFlags
  }

  get flags(): Flags {
    return this._flags
  }

  get isLoaded(): boolean {
    return this._isLoaded
  }

  private setIsLoaded(isLoaded: boolean) {
    this._isLoaded = isLoaded
  }

  push(flag: Flag) {
    this._flags.push(flag)
  }

  findByTime(time: number): Flag | undefined {
    return this._flags.find((flag) => flag.time === time)
  }

  getByIdOrCid(id: number, cid: number | undefined): Flag | undefined {
    return this._flags.find((flag) => (cid ? cid === flag.cid : id === flag.id))
  }

  getById(id: number): Flag | undefined {
    return this._flags.find((flag) => flag.id === id)
  }

  delete(id: number, cid?: number) {
    const flagToDelete = this.getByIdOrCid(id, cid)!
    const indexToDelete = this.flags.indexOf(flagToDelete)
    if (indexToDelete > -1) {
      this._flags.splice(indexToDelete, 1)
    }
  }

  update(updated: Flag, id: number, cid?: number) {
    const old = this.getByIdOrCid(id, cid)!
    const index = this.flags.indexOf(old)
    this._flags.splice(index, 1, updated)
  }
}
