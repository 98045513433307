import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { SliceDetailsCard } from 'components/ps-chart/details-view/SliceDetailsCard'
import { useTranslation } from 'react-i18next'
import { IconColored } from 'components/common/IconColored'
import { ActionTooltip } from 'components/ActionTooltip'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'

interface SliceConnectionsProps {
  psChartStore: PsChartStore
}

export const SliceConnections = observer(function SliceConnections({
  psChartStore,
}: SliceConnectionsProps) {
  const { t } = useTranslation()

  const onShowAllPathsClick = useCallback(() => {
    psChartStore.toggleShouldShowAllPaths()
  }, [psChartStore])

  const onDimDisconnectedSlicesClick = useCallback(() => {
    psChartStore.toggleIsDimDisconnectedSlicesEnabled()
  }, [psChartStore])

  const onFilterIconClick = useCallback(() => {
    psChartStore.traceAnalyzeStore.toggleThreadForceMaxLevelMode()
  }, [psChartStore])

  const onShrinkIconClick = useCallback(() => {
    psChartStore.traceAnalyzeStore.toggleThreadShrunkMode()
  }, [psChartStore])

  const onRegularButtonClick = useCallback(() => {
    psChartStore.traceAnalyzeStore.switchDetailsToRegularChain()
  }, [psChartStore])

  const onNativeButtonClick = useCallback(() => {
    psChartStore.traceAnalyzeStore.switchDetailsToNativeChain()
  }, [psChartStore])

  const chain = psChartStore.traceAnalyzeStore.mainDetailsSlices

  const showReactNativeControl =
    psChartStore.traceDataState.hasReactQueueThread &&
    psChartStore.traceAnalyzeStore.nativeChainExists &&
    psChartStore.traceAnalyzeStore.regularChainExists
  const isNativeButtonActive = psChartStore.traceAnalyzeStore.isDetailsChainNative
  const isRegularButtonActive = psChartStore.traceAnalyzeStore.isDetailsChainRegular

  return (
    <>
      <div className="mb-[15px] flex">
        <div className="font-medium text-small text-gray-normal flex-grow tracking-widest pt-[3px]">
          {t('psChart.details.connectionHeader')}
        </div>
        <div>
          <ActionTooltip
            tooltipId={
              psChartStore.traceAnalyzeStore.shouldShowAllPaths
                ? 'showMainExecutionPath'
                : 'showAllPaths'
            }
          >
            <IconColored
              onClick={onShowAllPathsClick}
              isActive={psChartStore.traceAnalyzeStore.shouldShowAllPaths}
              icon="paths"
              size={16}
              isDisabled={
                !psChartStore.traceAnalyzeStore.hasAlternativeChains ||
                psChartStore.traceAnalyzeStore.isForwardConnectionsEnabled
              }
              data-tid="main-exec-path-toggle"
            />
          </ActionTooltip>
          <ActionTooltip tooltipId="filter">
            <IconColored
              className="ml-[10px]"
              onClick={onFilterIconClick}
              isActive={
                psChartStore.traceAnalyzeStore.chainExists &&
                !psChartStore.traceAnalyzeStore.showMaxLevelMode
              }
              isDisabled={!psChartStore.traceAnalyzeStore.chainExists}
              icon="collapse"
              size={16}
              data-tid="thread-filter-toggle"
            />
          </ActionTooltip>
          <ActionTooltip tooltipId="collapseDisconnectedLevels">
            <IconColored
              className="ml-[10px]"
              onClick={onShrinkIconClick}
              isActive={
                psChartStore.traceAnalyzeStore.chainExists &&
                psChartStore.traceAnalyzeStore.showOnlyActiveLevelsMode
              }
              isDisabled={!psChartStore.traceAnalyzeStore.chainExists}
              icon="shrink-threads"
              size={16}
              data-tid="thread-filter-toggle"
            />
          </ActionTooltip>
          <ActionTooltip tooltipId="dimDisconnectedSlices">
            <IconColored
              className="ml-[10px]"
              onClick={onDimDisconnectedSlicesClick}
              isActive={psChartStore.shouldDimDisconnectedSlices}
              icon="dim-disconnected-slices"
              size={16}
              data-tid="dim-disconnected-slices"
              isDisabled={!psChartStore.traceAnalyzeStore.chainExists}
            />
          </ActionTooltip>
        </div>
      </div>
      {showReactNativeControl ? (
        <div className="mb-[15px] flex justify-around">
          <Button
            variant={ButtonVariantEnum.Text}
            textColorVariant={
              isNativeButtonActive
                ? ButtonTextColorVariantEnum.Primary
                : ButtonTextColorVariantEnum.Muted
            }
            onClick={onNativeButtonClick}
          >
            {t('psChart.details.reactJSTab')}
          </Button>
          <Button
            variant={ButtonVariantEnum.Text}
            textColorVariant={
              isRegularButtonActive
                ? ButtonTextColorVariantEnum.Primary
                : ButtonTextColorVariantEnum.Muted
            }
            onClick={onRegularButtonClick}
          >
            {t('psChart.details.regularTab')}
          </Button>
        </div>
      ) : null}
      {chain.map((slice, index) => (
        <SliceDetailsCard
          psChartStore={psChartStore}
          chain={chain}
          sliceIndex={index}
          key={slice.id}
        />
      ))}
    </>
  )
})
