import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {
  Flag,
  FlagSource,
  extractFlowTitleFromSliceTitle,
  generateColorFromNumber,
  FLOW_TITLE_REGEX,
  FLOW_TITLE_QUOTED_REGEX,
} from 'components/ps-chart/models/Flag'
import { FlagsStore } from 'components/ps-chart/stores/FlagsStore'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Icon } from 'components/Icon'

interface FlowListProps {
  flagsStore: FlagsStore
  psChartStore: PsChartStore
}

interface FlowGroup {
  flowId: number
  color: string
  title: string
  flags: Flag[]
  isVisible: boolean
}

export const FlowList = observer(function FlowList({ flagsStore, psChartStore }: FlowListProps) {
  const { t } = useTranslation()

  // Group flags by flowId
  const flowGroups = useMemo(() => {
    const groups: Record<number, FlowGroup> = {}

    // Filter for INSTRUMENTATION flags with flowId and sliceTitle
    const instrumentationFlags = flagsStore.flags.filter(
      (flag) => flag.source === FlagSource.INSTRUMENTATION && flag.flowId && flag.sliceTitle,
    )

    // First pass - Group flags by flowId
    instrumentationFlags.forEach((flag) => {
      if (!flag.flowId) {
        return
      }

      if (!groups[flag.flowId]) {
        // Initialize a new group with default title - isVisible will be determined in render
        groups[flag.flowId] = {
          flowId: flag.flowId,
          color: typeof flag.color === 'string' ? flag.color : generateColorFromNumber(flag.flowId),
          title: 'Unknown',
          flags: [],
          isVisible: true, // Default value, will be overridden in render
        }
      }

      // Add flag to its group
      groups[flag.flowId].flags.push(flag)
    })

    // Second pass - Find best title for each group
    Object.values(groups).forEach((group) => {
      // Try to find a flag with either pattern for best title extraction
      const flagWithPattern = group.flags.find(
        (flag) =>
          flag.sliceTitle &&
          (FLOW_TITLE_REGEX.test(flag.sliceTitle) || FLOW_TITLE_QUOTED_REGEX.test(flag.sliceTitle)),
      )

      if (flagWithPattern?.sliceTitle) {
        const extractedTitle = extractFlowTitleFromSliceTitle(flagWithPattern.sliceTitle)
        if (extractedTitle) {
          group.title = extractedTitle
        }
      }
    })

    // Convert to array and sort by flowId
    return Object.values(groups).sort((a, b) => a.flowId - b.flowId)
  }, [flagsStore.flags]) // Only depend on flagsStore.flags, not hiddenFlowsStore

  // If no flow groups, don't render anything
  if (flowGroups.length === 0) {
    return null
  }

  const toggleFlowVisibility = (e: React.MouseEvent, flowId: number) => {
    e.stopPropagation()
    psChartStore.hiddenFlowsStore.toggleFlow(flowId)
  }

  return (
    <div className="mt-[24px]">
      <div className="text-small text-gray-normal mb-[16px] mt-[16px] pb-[8px]">
        {t('psChart.details.flowGroups')}
      </div>
      <ul>
        {flowGroups.map((flowGroup) => {
          // Check visibility status here to ensure reactivity
          const isVisible = !psChartStore.hiddenFlowsStore.isHidden(flowGroup.flowId)

          return (
            <li
              key={flowGroup.flowId}
              className="mb-[8px] rounded-sm bg-dark-dark1 hover:bg-dark-dark2 transition-colors overflow-hidden"
            >
              <div
                className="flex items-center px-[16px] py-[12px] cursor-pointer relative"
                onClick={(e) => toggleFlowVisibility(e, flowGroup.flowId)}
              >
                <Icon
                  icon="eye"
                  className="mr-[12px]"
                  style={{
                    color: isVisible ? flowGroup.color : 'var(--gray-light, #9CA3AF)',
                  }}
                />
                <div className="flex-grow mr-[36px]">
                  <div className="text-normal text-white opacity-90 break-words">
                    {flowGroup.title}
                  </div>
                  <div className="text-small text-gray-normal">
                    Flow ID: {flowGroup.flowId} ({flowGroup.flags.length}{' '}
                    {flowGroup.flags.length === 1 ? 'flag' : 'flags'})
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
})
