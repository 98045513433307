export enum FlagSource {
  USER = 'USER',
  INSTRUMENTATION = 'INSTRUMENTATION',
}

/**
 * For now, we don't have direct link as we have it for annotation flags,
 * so we need to find it manually.
 **/
export const INSTRUMENTATION_FLAG_SOURCE_SLICE_SEARCH_TERM = '@FLOW'

/**
 * Regex pattern for extracting flow information from slice titles without quotes
 */
export const FLOW_TITLE_REGEX = /@FLOW\s+(-?\d+)\s+(\d+)\s+(.+)/

/**
 * Regex pattern for extracting flow information from slice titles with quotes
 * Example: @FLOW "2131429650" end "PPS logged for HomepageFragment}"
 */
export const FLOW_TITLE_QUOTED_REGEX = /@FLOW\s+"(-?\d+)"\s+\w+\s+"(.+)"/

export interface Flag {
  id: number
  cid?: number
  time: number
  color: number | string
  title: string
  source?: FlagSource
  // Associated slice information for INSTRUMENTATION flags
  sliceId?: number
  sliceTitle?: string
  // Extracted flow ID from sliceTitle for INSTRUMENTATION flags
  flowId?: number
  // Whether the flag should be visible in the UI
  visible?: boolean
}

/**
 * Extracts a flow ID from a slice title, supporting both quoted and unquoted formats
 * @param title - The slice title to extract flow ID from
 * @returns The extracted flow ID number or undefined if not found
 */
export const extractFlowIdFromTitle = (title: string): number | undefined => {
  const trimmedTitle = title.trim()

  // Check for the quoted ID pattern first
  const quotedMatch = trimmedTitle.match(FLOW_TITLE_QUOTED_REGEX)
  if (quotedMatch && quotedMatch.length >= 2) {
    return parseInt(quotedMatch[1], 10)
  }

  // Check for the regular pattern next
  const regularMatch = trimmedTitle.match(FLOW_TITLE_REGEX)
  if (regularMatch && regularMatch.length >= 2) {
    return parseInt(regularMatch[1], 10)
  }

  // Fallback to the old method if neither pattern matches
  const numberMatch = title.match(/\d+/)
  if (numberMatch && numberMatch.length) {
    return parseInt(numberMatch[0], 10)
  }

  return undefined
}

/**
 * Sanitizes a flow title by removing all special characters except spaces and underscores
 * @param title - The title to sanitize
 * @returns The sanitized title
 */
export const sanitizeFlowTitle = (title: string): string => {
  // Replace any character that's not a letter, number, space, or underscore with an empty string
  return title.replace(/[^a-zA-Z0-9\s_]/g, '')
}

/**
 * Extracts the flow title from a slice title, taking the text after the second number (128 or 256)
 * and returning only the component name (before any space or "END" suffix)
 * @param title - The slice title to extract flow title from (e.g., "@FLOW -1918002829 256 LoginIntroductionFragment END")
 * @returns The extracted flow title (e.g., "LoginIntroductionFragment") or undefined if not found
 */
export const extractFlowTitleFromSliceTitle = (title: string): string | undefined => {
  // Handle possible issues with leading/trailing whitespace
  const trimmedTitle = title.trim()

  // For debugging
  // console.log('Extracting title from:', trimmedTitle);

  let extractedTitle: string | undefined

  // Case 1: Regular pattern without quotes
  // Look for a pattern like "@FLOW <number> 128|256 <title>" - allowing for negative numbers
  const match = trimmedTitle.match(FLOW_TITLE_REGEX)
  if (match && match.length >= 4) {
    // Get the complete text after the marker (e.g., "LoginIntroductionFragment END")
    const fullTextAfterMarker = match[3].trim().split(/\s+/)

    // Extract the component name - the part before any space
    extractedTitle = fullTextAfterMarker[0].trim()
    // console.log('Case 1 extracted:', componentName);
  }
  // Case 2: Pattern with quoted ID and quoted title
  // Look for a pattern like '@FLOW "2131429650" end "PPS logged for HomepageFragment}"'
  else {
    const quotedMatch = trimmedTitle.match(FLOW_TITLE_QUOTED_REGEX)
    if (quotedMatch && quotedMatch.length >= 3) {
      // Get the complete quoted title text (e.g., "PPS logged for HomepageFragment}")
      const quotedTitle = quotedMatch[2].trim()

      // Split by spaces to get all words
      const words = quotedTitle.split(/\s+/)

      // If there's only one word, return it
      if (words.length === 1) {
        extractedTitle = words[0].trim()
      } else {
        // Take first and last word and combine them
        const firstWord = words[0].trim()
        const lastWord = words[words.length - 1].trim()
        // console.log('Case 2 extracted:', `${firstWord} ${lastWord}`);
        extractedTitle = `${firstWord} ${lastWord}`
      }
    }
    // Fallback: If we can't match the exact pattern but we know it contains @FLOW and 128/256,
    // try a simpler approach to extract everything after "128 " or "256 "
    else if (
      trimmedTitle.includes('@FLOW') &&
      (trimmedTitle.includes('128 ') || trimmedTitle.includes('256 '))
    ) {
      const pos128 = trimmedTitle.indexOf('128 ')
      const pos256 = trimmedTitle.indexOf('256 ')

      let extractedPart = ''
      if (pos128 > 0) {
        extractedPart = trimmedTitle.substring(pos128 + 4).trim()
      } else if (pos256 > 0) {
        extractedPart = trimmedTitle.substring(pos256 + 4).trim()
      }

      // Take only the component name (part before any space)
      if (extractedPart) {
        extractedTitle = extractedPart.split(/\s+/)[0].trim()
        // console.log('Fallback extracted:', firstWord);
      }
    }
  }

  // Sanitize the extracted title if one was found
  if (extractedTitle) {
    return sanitizeFlowTitle(extractedTitle)
  }

  return undefined
}

export const getColor = (flag: Flag): string | number => {
  const hexRegex = /#(?:[0-9a-fA-F]{3}){1,2}\b/
  if (flag.source === FlagSource.INSTRUMENTATION) {
    // First check if there's a hex color in the title
    const hexMatch = flag.title.match(hexRegex)
    if (hexMatch !== null && hexMatch.length) {
      return hexMatch[0]
    }

    // If flowId has been extracted, use it to generate a consistent color
    if (flag.flowId) {
      return generateColorFromNumber(flag.flowId)
    }

    // Default fallback
    return 0
  }

  return flag.color
}

/**
 * Generates a consistent hex color from a number
 * @param num - Number to generate color from
 * @returns Hex color string (e.g., #RRGGBB)
 */
export const generateColorFromNumber = (num: number): string => {
  // Use the number to generate a hash
  const hash = Math.abs(num)

  // Create a consistent but varied color by taking modulos
  // Use golden ratio to get better distribution of colors
  const hue = hash % 360 | 0
  const saturation = 65 + (hash % 25)
  const lightness = 45 + (hash % 15)

  // Convert HSL to RGB to Hex
  return hslToHex(hue, saturation, lightness)
}

/**
 * Converts HSL color values to a hex string
 * @param h - Hue (0-360)
 * @param s - Saturation (0-100)
 * @param l - Lightness (0-100)
 * @returns Hex color string
 */
export const hslToHex = (h: number, s: number, l: number): string => {
  s /= 100
  l /= 100

  const c = (1 - Math.abs(2 * l - 1)) * s
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  const m = l - c / 2

  let r = 0
  let g = 0
  let b = 0

  if (h >= 0 && h < 60) {
    r = c
    g = x
    b = 0
  } else if (h >= 60 && h < 120) {
    r = x
    g = c
    b = 0
  } else if (h >= 120 && h < 180) {
    r = 0
    g = c
    b = x
  } else if (h >= 180 && h < 240) {
    r = 0
    g = x
    b = c
  } else if (h >= 240 && h < 300) {
    r = x
    g = 0
    b = c
  } else if (h >= 300 && h < 360) {
    r = c
    g = 0
    b = x
  }

  // Convert to hex
  const toHex = (component: number) => {
    const hex = Math.round((component + m) * 255).toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

/**
 * Flag display mode options for timeline rendering
 * - none: Don't display any flags
 * - instrumentation: Only display instrumentation flags
 * - all: Display all flags (default for trace viewer)
 */
export enum FlagDisplayMode {
  NONE = 'none',
  INSTRUMENTATION = 'instrumentation',
  ALL = 'all',
}
