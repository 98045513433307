import { Flag, FlagSource } from 'components/ps-chart/models/Flag'
import { makeAutoObservable, observable, runInAction } from 'mobx'
import { Api } from 'api/Api'
import { ChartPageParams } from 'api/models'
import { FlagsSettings } from 'components/ps-chart/local-timeline/flags/FlagsSettings'
import { getRandomInt } from 'utils/random'
import { PsChartFeatures } from 'components/ps-chart/PsChartStore'
import { FlagsDataStore } from 'components/ps-chart/stores/FlagsDataStore'

export type Flags = ReadonlyArray<Flag>

export interface FlagsState {
  readonly flags: Flags
  readonly selectedFlag: Flag | null
  readonly selectedFlagId: number | null
  readonly selectedFlagTitle: string
  readonly selectedFlagTime: number
  readonly selectedFlagColorId: number
  readonly selectedFlagColor: string
  readonly hoverTime: number | null
  readonly showLabels: boolean
  readonly settings: FlagsSettings
  readonly enabled: boolean
}

export class FlagsStore implements FlagsState {
  private _selectedId: number | null = null
  private _hoverTime: number | null = null
  private _showLabels = true
  private readonly chartFeatures: PsChartFeatures

  private readonly api: Api
  private readonly chartPageParams: Required<ChartPageParams>
  readonly settings: FlagsSettings
  readonly flagsDataStore: FlagsDataStore

  constructor(
    api: Api,
    chartPageParams: Required<ChartPageParams>,
    flagsDataStore: FlagsDataStore,
    settings: FlagsSettings,
    chartFeatures: PsChartFeatures,
  ) {
    makeAutoObservable<FlagsStore, '_flags' | 'api' | 'chartPageParams'>(this, {
      _flags: observable,
      api: false,
      chartPageParams: false,
      settings: false,
    })
    this.api = api
    this.chartPageParams = chartPageParams
    this.flagsDataStore = flagsDataStore
    this.settings = settings
    this.chartFeatures = chartFeatures
  }

  get flags(): Flags {
    return this.flagsDataStore.flags
  }

  get selectedFlagId(): number | null {
    return this._selectedId
  }

  get selectedFlag(): Flag | null {
    return this.selectedFlagId ? this.flagsDataStore.getById(this.selectedFlagId) ?? null : null
  }

  get selectedFlagTitle(): string {
    const flag = this.flagsDataStore.getById(this.selectedFlagId ?? 0)
    return flag ? flag.title : ''
  }

  get selectedFlagTime(): number {
    const flag = this.flagsDataStore.getById(this.selectedFlagId ?? 0)
    return flag ? flag.time : 0
  }

  get selectedFlagColorId(): number {
    const flag = this.flagsDataStore.getById(this.selectedFlagId ?? 0)
    return flag && typeof flag.color === 'number' ? flag.color : 0
  }

  get selectedFlagColor(): string {
    return this.settings.colors[this.selectedFlagColorId]
  }

  get selectedFlagIsAuto(): boolean {
    return this.selectedFlag?.source === FlagSource.INSTRUMENTATION
  }

  get hoverTime(): number | null {
    return this._hoverTime
  }

  get showLabels(): boolean {
    return this._showLabels
  }

  get enabled(): boolean {
    return this.chartFeatures.flags.enabled
  }

  toggleShowLabels() {
    this._showLabels = !this._showLabels
  }

  add(time: number): Promise<Flag> {
    const cid = Date.now()
    const id = -cid
    const flag: Flag = {
      cid: cid,
      time: time,
      title: '',
      color: this.calcNextColor(),
      id: id,
    }

    const found = this.flagsDataStore.findByTime(time)
    if (found) {
      return Promise.resolve(found)
    }
    this.flagsDataStore.push(flag)
    this.select(id)
    return this.api
      .postFlag(this.chartPageParams, flag)
      .then((flagFromServer) => {
        this.select(flagFromServer.id)
        return this.runInActionUpdateFromServer(id, cid, flagFromServer)
      })
      .catch((reason) => {
        this.clearSelected()
        runInAction(() => this.flagsDataStore.delete(id, cid))
        return Promise.reject(reason)
      })
  }

  public remove(id: number, cid?: number): Promise<void> {
    const flag = this.flagsDataStore.getByIdOrCid(id, cid)!
    this.flagsDataStore.delete(id, cid)
    if (this.selectedFlagId === id) {
      this.clearSelected()
    }
    return this.api.deleteFlag(this.chartPageParams, flag).catch((reason) => {
      runInAction(() => this.flagsDataStore.push(flag))
      return Promise.reject(reason)
    })
  }

  updateTimeLocally(time: number, id: number, cid?: number) {
    const old = this.flagsDataStore.getByIdOrCid(id, cid)!
    const updated = { ...old, time }
    this.flagsDataStore.update(updated, id, cid)
  }

  updateTime(time: number, id: number, cid?: number): Promise<Flag> {
    const old = this.flagsDataStore.getByIdOrCid(id, cid)!
    const updated = { ...old }
    if (updated.time !== time) {
      console.warn('Update time not matches the flag time')
      updated.time = time
    }
    return this.api
      .putFlag(this.chartPageParams, updated)
      .then((flagFromServer) => this.runInActionUpdateFromServer(id, cid, flagFromServer))
  }

  select(id: number, cid?: number) {
    if (cid) {
      const flag = this.flagsDataStore.getByIdOrCid(id, cid)!
      this._selectedId = flag.id
    } else {
      this._selectedId = id
    }
  }

  clearSelected() {
    this._selectedId = null
  }

  updateSelectedColorId(colorId: number): Promise<Flag> {
    const id = this.selectedFlagId!
    const old = this.flagsDataStore.getById(id)!
    const updated = { ...old, color: colorId }
    this.flagsDataStore.update(updated, id)
    return this.api
      .putFlag(this.chartPageParams, updated)
      .then((flagFromServer) => this.runInActionUpdateFromServer(id, updated.cid, flagFromServer))
      .catch((reason) => {
        this.runInActionRollback(id, updated.cid, (tempFlag) => (tempFlag.color = old.color))
        return Promise.reject(reason)
      })
  }

  updateSelectedTitle(text: string): Promise<Flag> {
    const id = this.selectedFlagId!
    const old = this.flagsDataStore.getById(id)!
    const updated = { ...old, title: text }
    this.flagsDataStore.update(updated, updated.id)
    return this.api
      .putFlag(this.chartPageParams, updated)
      .then((flagFromServer) => this.runInActionUpdateFromServer(id, updated.cid, flagFromServer))
      .catch((reason) => {
        this.runInActionRollback(id, updated.cid, (tempFlag) => (tempFlag.title = old.title))
        return Promise.reject(reason)
      })
  }

  removeSelectedFlag(): Promise<void> {
    if (!this.selectedFlagId) {
      return Promise.reject('No selected flags to remove')
    }
    return this.remove(this.selectedFlagId!)
  }

  private runInActionUpdateFromServer(
    id: number,
    cid: number | undefined,
    flagFromServer: Flag,
  ): Flag {
    runInAction(() => this.flagsDataStore.update(flagFromServer, id, cid))
    return flagFromServer
  }

  private runInActionRollback(
    id: number,
    cid: number | undefined,
    rollbackAction: (flag: Flag) => void,
  ) {
    runInAction(() => {
      const flag = this.flagsDataStore.getByIdOrCid(id, cid)!
      rollbackAction(flag)
      this.flagsDataStore.update(flag, id, cid)
    })
  }

  private calcNextColor() {
    const countByColorFlags: Map<number, number> = new Map()
    for (const flag of this.flags) {
      if (typeof flag.color !== 'number') {
        continue
      }
      let count = countByColorFlags.get(flag.color)
      if (!count) {
        count = 0
      }
      countByColorFlags.set(flag.color, count + 1)
    }

    /** Fill not yet taken colors to {@link countByColorFlags} map */
    const allColors: number[] = [...Array(this.settings.colors.length).keys()]
    allColors.forEach((color) => {
      const colorFromFlagColors = countByColorFlags.get(color)
      if (!colorFromFlagColors) {
        countByColorFlags.set(color, 0)
      }
    })

    const nextColors: number[] = []

    const countByColorArray = [...countByColorFlags.entries()].sort((a, b) => a[1] - b[1])
    const leastCount: number = countByColorArray[0][1]
    for (const tempColorArray of countByColorArray) {
      const color = tempColorArray[0]
      const count = tempColorArray[1]
      if (count === leastCount) {
        nextColors.push(color)
      }
    }

    const index = getRandomInt(0, nextColors.length - 1)
    return nextColors[index]
  }

  setShowHover(time: number | null) {
    this._hoverTime = time
  }
}
