import { useMemo, useEffect } from 'react'
import { generatePath, useParams, Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { UserMenu } from 'components/lite/UserMenu'

import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { LiteLayout, LiteLayoutContainer } from 'components/lite/LiteLayout'
import { LiteHeader } from 'components/lite/LiteHeader'
import { LiteFooter } from 'components/lite/LiteFooter'
import { ErrorPageAuthorized } from 'pages/ErrorPageAuthorized'
import { chartDataStoreContext } from 'components/ps-chart/stores/ChartDataStore'
import { useTraceQuery, useAllProjectsQuery, useFlowsQuery } from 'hooks/useApiQuery'

import { useChartStores } from 'hooks/useChartStores'

import { PsChart } from 'components/ps-chart/PsChart'
import { psChartStoreContext } from 'components/ps-chart/PsChartStore'

import { useIsLiteUser } from 'components/lite/useIsLiteUser'

import { PATH_LITE_TRACES } from 'pages/lite/LiteTracesPage'
import { FlagDisplayMode } from 'components/ps-chart/models/Flag'

export const PATH_LITE_PS_CHART = !!process.env.REACT_APP_IS_LITE
  ? '/traces/:traceProjectLocalId'
  : '/lite/traces/:traceProjectLocalId'

export const getTraceUrl = (traceProjectLocalId: string) =>
  generatePath(PATH_LITE_PS_CHART, { traceProjectLocalId })

export const LitePsChartPage = () => {
  const isLite = useIsLiteUser(true)

  const { traceProjectLocalId } = useParams()

  const { data: projects } = useAllProjectsQuery()
  const projectUrlName = projects?.[0]?.urlName
  const { data: flows } = useFlowsQuery({ projectUrlName })
  const flowProjectLocalId = flows?.[0]?.projectLocalId
    ? String(flows?.[0]?.projectLocalId)
    : undefined

  if (!traceProjectLocalId || !projectUrlName || !flowProjectLocalId || !isLite) {
    return null
  }

  return (
    <ChartRoot
      traceProjectLocalId={traceProjectLocalId}
      projectUrlName={projectUrlName}
      flowProjectLocalId={flowProjectLocalId}
    />
  )
}

const ChartRoot = observer(function ChartRoot({
  traceProjectLocalId,
  projectUrlName,
  flowProjectLocalId,
}: {
  traceProjectLocalId: string
  projectUrlName: string
  flowProjectLocalId: string
}) {
  const { t } = useTranslation()
  const chartPageParams = useMemo(
    () => ({ projectUrlName, traceProjectLocalId, flowProjectLocalId }),
    [projectUrlName, traceProjectLocalId, flowProjectLocalId],
  )
  const { psChartStore, chartDataStore, error } = useChartStores({
    isReadOnlyProject: false,
    chartPageParams,
  })

  useEffect(() => {
    if (psChartStore.isLoaded) {
      psChartStore.setChartFeatures({
        flags: { enabled: true, clickable: true, mode: FlagDisplayMode.ALL },
        measurementTool: true,
        annotations: { enabled: false, draggable: false, clickable: false, flowAccess: false },
        directionTool: false,
      })
    }
  }, [psChartStore, psChartStore.isLoaded])

  const { data: trace } = useTraceQuery({
    projectUrlName,
    traceProjectLocalId,
  })

  if (error) {
    return <ErrorPageAuthorized fullReload={true} />
  }

  if (chartDataStore.isLoading) {
    return <ModuleProgressCircle />
  }

  return (
    <chartDataStoreContext.Provider value={chartDataStore}>
      <psChartStoreContext.Provider value={psChartStore}>
        <LiteLayout
          header={<Header />}
          pageTitle={trace?.name}
          className="bg-dark-dark5 text-white md:flex hidden"
          bodyClassName="min-h-full flex flex-grow flex-col"
        >
          <PsChart psChartStore={psChartStore} />
        </LiteLayout>
        <LiteLayout
          header={<LiteHeader isRootPage />}
          footer={<LiteFooter />}
          pageTitle={trace?.name}
          className="md:hidden flex"
        >
          <LiteLayoutContainer>
            <p>{t('lite.psChart.mobile')}</p>
          </LiteLayoutContainer>
        </LiteLayout>
      </psChartStoreContext.Provider>
    </chartDataStoreContext.Provider>
  )
})

const Header = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-nowrap text-gray-normal mb-1 mx-5">
      <h3 className="text-header-small relative mt-2">
        <Link to={PATH_LITE_TRACES}>{t('lite.psToolLite')}</Link>
        <span className="text-gray-faded absolute top-0 text-small">{t('lite.beta')}</span>
      </h3>
      <div className="flex-grow"></div>
      <div className="min-w-[100px] flex items-end flex-col">
        <UserMenu isDark />
      </div>
    </div>
  )
}
