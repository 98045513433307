import React, { useContext, useMemo } from 'react'

import { Layout } from 'components/Layout'
import { PsAnnotations } from 'components/annotations/PsAnnotations'
import { PsChartFeatures, psChartStoreContext } from 'components/ps-chart/PsChartStore'
import { useNavigationRules } from 'hooks/useNavigationRules'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { Navbar } from 'components/Navbar'
import { TracePageBreadcrumbs } from 'components/breadcrumbs/TracePageBreadcrumbs'
import { FlagDisplayMode } from 'components/ps-chart/models/Flag'

export const PATH_ANNOTATIONS_FOLDER = 'annotations'
export const PATH_ANNOTATIONS = `/projects/:projectUrlName/flows/:flowProjectLocalId/traces/:traceProjectLocalId/${PATH_ANNOTATIONS_FOLDER}`

const annotationPageChartFeatures: PsChartFeatures = {
  measurementTool: false,
  flags: {
    enabled: true,
    clickable: false,
    mode: FlagDisplayMode.INSTRUMENTATION,
  },
  annotations: { enabled: true, draggable: true, clickable: true, flowAccess: true },
  directionTool: false,
}

export const PsAnnotationsPage = () => {
  const psChartStore = useContext(psChartStoreContext)!
  psChartStore.setChartFeatures(annotationPageChartFeatures)

  useNavigationRules()

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={<TracePageBreadcrumbs />}
        centerContent={<PageTitle titleKey="annotations" />}
      />
    ),
    [],
  )
  const footer = useMemo(() => <Navbar />, [])

  return (
    <Layout
      headerComponent={header}
      footerComponent={footer}
      pageConfig={{ withoutStyledContent: true }}
    >
      <PsAnnotations psChartStore={psChartStore} />
    </Layout>
  )
}
